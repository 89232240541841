import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createState } from './state/createState';
import { initialStateFactory } from './state/initialStateFactory';
import {
  createDailyAgendaActions,
  DailyAgendaActions,
} from './actions/actions';
import {
  createDailyAgendaViewModel,
  WidgetViewModel,
} from './viewModel/viewModel';
import { initViewModelSubscriptions } from './viewModel/initViewModelSubscriptions';
import { initWidgetData } from '../../adapters/initWidgetData/initWidgetData';

export type ControllerProps = {
  viewModel: WidgetViewModel;
  actions?: DailyAgendaActions;
};

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const setProps: (props: ControllerProps) => void = controllerConfig.setProps;
  return {
    async pageReady() {
      const initialState = initialStateFactory({ flowAPI });

      const { getState, setState, subscribe } = createState(initialState);

      const actions = createDailyAgendaActions({ getState, setState, flowAPI });

      const initialViewModel = createDailyAgendaViewModel({
        state: getState(),
        flowAPI,
      });

      setProps({ viewModel: initialViewModel, actions });

      initViewModelSubscriptions({
        getState,
        flowAPI,
        subscribe,
        setProps,
        viewModel: initialViewModel,
      });

      initWidgetData({
        flowAPI,
        setState,
      });
    },
  };
};

export default createController;
