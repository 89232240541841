import settingsParams from '../../settingsParams';
import { WidgetViewModelFactory } from '../viewModel';

export type TitleViewModel = {
  title: string;
  titleTagName: string;
};

export const createTitleViewModel: WidgetViewModelFactory<TitleViewModel> = ({
  flowAPI: { settings },
}) => {
  return {
    title: settings.get(settingsParams.titleText),
    titleTagName: settings.get(settingsParams.titleTagName),
  };
};
