import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SetState } from '../../components/DailyAgenda/state/createState';
import { initServices } from './initServices/initServices';
import { initStaffMembers } from './initStaffMembers/initStaffMembers';
import { initSlots } from './initSlots/initSlots';
import { initAvailableDays } from './initAvailableDays/initAvailableDays';

export type InitiatorsArgs = {
  flowAPI: ControllerFlowAPI;
  setState: SetState;
};

export const initWidgetData = (args: InitiatorsArgs) => {
  initServices(args);
  initStaffMembers(args);
  initSlots(args);
  initAvailableDays(args);
};
