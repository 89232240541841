import { TimeSlot } from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  formatRfcTimeStringToTimeSlotView,
  fromLocalDateTimeToRfc,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { getDurationText } from '@wix/bookings-catalog-calendar-viewer-utils/duration';
import { ViewModelFactoryArgs } from '../../../state/types';
import { getPaymentDescription } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  isSlotLocked,
  isSlotWithBookingsPolicyViolation,
  isFullSlot,
} from '../../../../../utils/slotAvailability/slotAvailability';
import settingsParams from '../../../settingsParams';

export type AgendaItemViewModel = {
  serviceName?: string;
  serviceImage?: { src: string; alt?: string };
  image?: any;
  startTime?: string;
  duration?: string;
  staffName?: string;
  staffImage?: string;
  location?: string;
  spots?: string;
  price?: string;
  buttonText?: string;
  disabled?: boolean;
  slotId: string;
};

export const createAgendaItemViewModel = ({
  slot,
  service,
  state: { languageSettings },
  flowAPI,
}: {
  slot: TimeSlot;
  service?: Service;
} & ViewModelFactoryArgs): AgendaItemViewModel => {
  const rfcStartTime = fromLocalDateTimeToRfc(slot.localStartDate!);
  const rfcEndTime = fromLocalDateTimeToRfc(slot.localEndDate!);

  const location = service?.locations?.find(
    ({ id }) => slot.location?.id === id,
  )?.business?.name;

  const formattedTime = formatRfcTimeStringToTimeSlotView(rfcStartTime);

  const serviceName = service?.name!;

  const staffMember = slot.availableResources?.[0].resources?.[0];
  const staffMemberName = staffMember?.name!; // not translated

  // TODO - dummy data

  const duration = getDurationText({
    dateRegionalSettingsLocale: languageSettings.dateRegionalSettingsLocale,
    rfcStartTime,
    rfcEndTime,
    t: flowAPI.translations.t,
  }).durationText;

  const price = getSlotPrice({
    service: service!,
    regionalSettingsLocale: languageSettings.regionalSettingsLocale!,
    t: flowAPI.translations.t,
  });

  const spotsLeft = getSpotsLeft({ slot, flowAPI });

  // const policyViolation = getPolicyViolationText({
  //   context,
  //   slot: enrichedSlot,
  // });

  // const ctaViewModel = isCtaVisible
  //   ? createCtaViewModel({ state, context, slots: [enrichedSlot] })
  //   : undefined;

  return {
    slotId: slot.eventInfo?.eventId!,
    startTime: formattedTime,
    serviceName,
    location,
    spots: spotsLeft,
    duration,
    price,
    staffName: staffMemberName!,
    // buttonText:
    // disabled: false,
    serviceImage: service?.media?.mainMedia?.image
      ? {
          src: service?.media?.mainMedia?.image?.url!,
          alt: service?.media?.mainMedia?.image?.altText!,
        }
      : undefined,
  };
};

const getSpotsLeft = ({
  slot,
  flowAPI: {
    settings,
    translations: { t },
  },
}: {
  slot: TimeSlot;
  flowAPI: ControllerFlowAPI;
}) => {
  const openSpots = slot.remainingCapacity!;

  if (isSlotWithBookingsPolicyViolation(slot)) {
    return;
  }

  if (isSlotLocked(slot)) {
    return;
  }

  if (isFullSlot(slot)) {
    return settings.get(settingsParams.noSpotsLeft);
  }

  const customFormat = settings.get(settingsParams.spotsLeftFormat);
  if (customFormat) {
    return t('app.agenda-slot.spots-left.custom-label', {
      openSpots,
      spotsLeftFormat: customFormat,
    });
  }

  return t('app.agenda-slot.spots-left.label', {
    openSpots,
  });
};

const getSlotPrice = ({
  t,
  service,
  regionalSettingsLocale,
}: {
  service: Service;
  regionalSettingsLocale: string;
  t: any;
}): string => {
  const payment = service.payment!;

  const getPaymentDescriptionArgs: Parameters<typeof getPaymentDescription>[0] =
    {
      payment,
      regionalSettingsLocale,
      paymentFormatters: {
        variedPrice: ({ minPrice }) =>
          t('payment.from-price', { price: minPrice }),
      },
    };

  return getPaymentDescription(getPaymentDescriptionArgs);
};

// const getPolicyViolationText = ({
//   context,
//   slot,
// }: {
//   context: CalendarContext;
//   slot: EnrichedSlotAvailability;
// }): string | undefined => {
//   const { getContent, settingsParams } = context;

//   if (isTooEarlyToBookSlot(slot)) {
//     return getContent({
//       settingsParam: settingsParams.toEarlyToBookIndication,
//       translationKey: 'app.settings.defaults.policy.to-early-to-book',
//     });
//   }

//   if (isTooLateToBookSlot(slot)) {
//     return getContent({
//       settingsParam: settingsParams.toLateToBookIndication,
//       translationKey: 'app.settings.defaults.policy.to-late-to-book',
//     });
//   }
// };
