import { Filter, FilterType } from '../../../../types/types';
import settingsParams from '../../settingsParams';
import { AsyncComponentDataStatus } from '../../state/types';
import { ViewModelSubscription } from '../initViewModelSubscriptions';
import { WidgetViewModelFactory } from '../viewModel';

export type FiltersViewModel = {
  filters: Filter[];
};

export const createFiltersViewModel: WidgetViewModelFactory<
  FiltersViewModel
> = ({
  state: { selectedFilters, services, locations, staffMembers },
  flowAPI: { settings },
}) => {
  const filters = [];
  const isOptionSelected = (type: FilterType, id: string) => {
    const filterType = selectedFilters.find((filter) => filter.type === type);
    return filterType?.options.find((option) => option.id === id)?.selected;
  };

  if (services.status === AsyncComponentDataStatus.IDLE) {
    const servicesFilter = {
      type: FilterType.SERVICE,
      label: settings.get(settingsParams.serviceLabel),
      options: Object.values(services.data).map((service) => ({
        selected: !!isOptionSelected(FilterType.SERVICE, service.id!),
        id: service.id!,
        value: service.name!,
      })),
    };

    if (servicesFilter.options.length > 1) {
      filters.push(servicesFilter);
    }
  }

  if (locations.status === AsyncComponentDataStatus.IDLE) {
    const locationsFilter = {
      type: FilterType.LOCATION,
      label: settings.get(settingsParams.locationLabel),
      options: Object.values(locations.data).map((location) => ({
        selected: !!isOptionSelected(FilterType.LOCATION, location.id!),
        id: location.id!,
        value: location.business?.name!,
      })),
    };

    if (locationsFilter.options.length > 1) {
      filters.push(locationsFilter);
    }
  }

  if (staffMembers.status === AsyncComponentDataStatus.IDLE) {
    const staffMembersFilter = {
      type: FilterType.STAFF_MEMBER,
      label: settings.get(settingsParams.staffMemberLabel),
      options: Object.values(staffMembers.data).map((staffMember) => ({
        selected: !!isOptionSelected(FilterType.STAFF_MEMBER, staffMember.id!),
        id: staffMember.id!,
        value: staffMember.name!,
      })),
    };
    if (staffMembersFilter.options.length > 1) {
      filters.push(staffMembersFilter);
    }
  }

  return { filters };
};

export const subscribeToFiltersViewModel: ViewModelSubscription = ({
  setViewModel,
  subscribe,
  flowAPI,
  getState,
}) => {
  subscribe(
    ({ selectedFilters, services, locations, staffMembers }) => ({
      selectedFilters,
      services,
      locations,
      staffMembers,
    }),
    (state) => {
      const filtersViewModel = createFiltersViewModel({
        state: { ...getState(), ...state },
        flowAPI,
      });
      setViewModel({ filtersViewModel });
    },
  );
};
