import { queryStaffMembers } from '@wix/ambassador-bookings-staff-v1-staff-member/http';
import { InitiatorsArgs } from '../initWidgetData';
import { AsyncComponentDataStatus } from '../../../components/DailyAgenda/state/types';
import type { IHttpError } from '@wix/http-client';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { manageWarmupData } from '@wix/bookings-catalog-calendar-viewer-utils';

export const initStaffMembers = async (args: InitiatorsArgs) => {
  const { flowAPI, setState } = args;
  const useWarmupData = flowAPI.experiments.enabled(
    'specs.bookings.agendaWarmupDataForStaffMembers',
  );
  try {
    const staffMembers = useWarmupData
      ? await manageWarmupData(
          () => fetchStaffMembers(flowAPI),
          'staffMembers',
          flowAPI,
        )
      : await fetchStaffMembers(flowAPI);

    if (!staffMembers || staffMembers.length === 0) {
      setState({
        staffMembers: {
          status: AsyncComponentDataStatus.ERROR,
          data: 'No staff members found',
        },
      });
      return;
    }

    const staffMembersData = staffMembers.reduce((acc, member) => {
      acc[member.id!] = member;
      return acc;
    }, {} as Record<string, any>);

    setState({
      staffMembers: {
        status: AsyncComponentDataStatus.IDLE,
        data: staffMembersData,
      },
    });
  } catch (error) {
    setState({
      staffMembers: {
        status: AsyncComponentDataStatus.ERROR,
        data: (error as IHttpError).response?.data,
      },
    });
    console.error('Error initializing staff members:', error);
  }
};

const fetchStaffMembers = async (flowAPI: ControllerFlowAPI) => {
  const response = await flowAPI.httpClient.request(queryStaffMembers({}));
  return response.data.staffMembers;
};
