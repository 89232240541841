import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { AsyncComponentDataStatus, DailyAgendaState } from './types';
import { getLanguageSettings } from '@wix/bookings-catalog-calendar-viewer-utils';

export const initialStateFactory = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): DailyAgendaState => {
  const now = new Date();

  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);

  return {
    activeFeatures: { status: AsyncComponentDataStatus.LOADING },
    slotsMetaData: { status: AsyncComponentDataStatus.LOADING },
    locations: { status: AsyncComponentDataStatus.LOADING },
    staffMembers: { status: AsyncComponentDataStatus.LOADING },
    slots: { status: AsyncComponentDataStatus.LOADING },
    availableDays: { status: AsyncComponentDataStatus.LOADING },
    services: { status: AsyncComponentDataStatus.LOADING },
    selectedDate: now,
    selectedRange: { from: now, to: nextWeek },
    selectedTimezone: flowAPI.controllerConfig.wixCodeApi.site.timezone!, // TODO should be default time zone?
    selectedFilters: [],
    languageSettings: getLanguageSettings(flowAPI.controllerConfig.wixCodeApi),
  };
};
