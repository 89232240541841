import { listEventTimeSlots } from '../../api/listEventTimeSlots/listEventTimeSlots';
import { InitiatorsArgs } from '../initWidgetData';
import type { IHttpError } from '@wix/http-client';
import settingsParams from '../../../components/DailyAgenda/settingsParams';
import { AsyncComponentDataStatus } from '../../../components/DailyAgenda/state/types';

export const initSlots = async (args: InitiatorsArgs) => {
  const { flowAPI, setState } = args;
  const timeSlotsBeforeLoadMore = flowAPI.settings.get(
    settingsParams.timeSlotsBeforeLoadMore,
  );
  try {
    const { timeSlots } = await listEventTimeSlots({
      flowAPI,
      range: {
        from: new Date(),
      },
      limit: timeSlotsBeforeLoadMore,
    });

    if (!timeSlots || timeSlots.length === 0) {
      setState({
        slots: {
          status: AsyncComponentDataStatus.ERROR,
          data: 'No slots found',
        },
      });
      return;
    }

    setState({
      slots: {
        status: AsyncComponentDataStatus.IDLE,
        data: timeSlots,
      },
    });
  } catch (error) {
    setState({
      slots: {
        status: AsyncComponentDataStatus.ERROR,
        data: (error as IHttpError).response?.data,
      },
    });
    console.error('Error initializing slots:', error);
  }
};
