import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { AsyncComponentDataStatus } from '../../../components/DailyAgenda/state/types';
import {
  queryServices,
  SERVICES_PAGE_SIZE,
} from '../../api/queryServices/queryServices';
import { InitiatorsArgs } from '../initWidgetData';
import type { IHttpError } from '@wix/http-client';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { manageWarmupData } from '@wix/bookings-catalog-calendar-viewer-utils';

export const initServices = async (args: InitiatorsArgs) => {
  const { flowAPI, setState } = args;
  const useWarmupData = flowAPI.experiments.enabled(
    'specs.bookings.agendaWarmupDataForServices',
  );
  try {
    const services = useWarmupData
      ? await manageWarmupData(
          () => fetchServices(flowAPI),
          'services',
          flowAPI,
        )
      : await fetchServices(flowAPI);
    if (!services || services.length === 0) {
      setState({
        services: {
          status: AsyncComponentDataStatus.ERROR,
          data: 'No services found',
        },
      });
      return;
    }
    const servicesData = services.reduce((acc, service) => {
      acc[service.id!] = service;
      return acc;
    }, {} as Record<string, Service>);
    setState({
      services: {
        status: AsyncComponentDataStatus.IDLE,
        data: servicesData,
      },
    });
  } catch (error) {
    setState({
      services: {
        status: AsyncComponentDataStatus.ERROR,
        data: (error as IHttpError).response?.data,
      },
    });
    console.error('Error initializing services:', error);
  }
};

const fetchServices = async (flowAPI: ControllerFlowAPI) => {
  const { services: servicesFirstPage, pagingMetadata } = await queryServices({
    flowAPI,
  });

  let services: Service[] = servicesFirstPage ?? [];

  if (pagingMetadata?.hasNext && pagingMetadata?.total) {
    const numOfPages =
      Math.ceil(pagingMetadata?.total / SERVICES_PAGE_SIZE) - 1;
    const pageOffsets = Array.from(
      { length: numOfPages },
      (_, i) => i * SERVICES_PAGE_SIZE + SERVICES_PAGE_SIZE,
    );

    const allServices = [
      ...(await Promise.all(
        pageOffsets.map((offset) =>
          queryServices({
            flowAPI,
            paging: { offset },
          }).then((res) => res.services ?? []),
        ),
      )),
    ];

    services = [services, ...allServices].flat();
  }

  if (!services || services.length === 0) {
    return;
  }
  return services;
};
