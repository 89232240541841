import {
  Paging,
  QueryServicesRequest,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { queryServices as queryServicesApi } from '@wix/ambassador-bookings-services-v2-service/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getQueryFiltersFromSettings,
  mergeQeuryFilters,
} from '../queryFilters/queryFilters';
import { QueryFilters } from '../types';

export const SERVICES_PAGE_SIZE = 100;

export const queryServices = async ({
  flowAPI: { settings, httpClient },
  paging,
  filters,
}: {
  filters?: QueryFilters;
  flowAPI: ControllerFlowAPI;
  paging?: Paging;
}) => {
  const { serviceIds, businessLocationIds, categories } = mergeQeuryFilters(
    getQueryFiltersFromSettings(settings),
    filters,
  );
  const filter: QueryServicesRequest['query']['filter'] = {
    ...(serviceIds.length > 0 ? { id: { $in: serviceIds } } : {}),
    ...(businessLocationIds.length > 0
      ? { 'locations.business.id': { $hasSome: businessLocationIds } }
      : {}),
    ...(categories.length > 0
      ? {
          'category.id': {
            $in: categories,
          },
        }
      : {}),
    type: ServiceType.CLASS,
    $or: [{ hidden: false }, { hidden: { $exists: false } }],
  };
  const response = await httpClient.request(
    queryServicesApi({
      query: { paging: { limit: SERVICES_PAGE_SIZE, ...paging }, filter },
    }),
  );
  return response.data;
};
