import { ViewModelFactoryArgs } from '../state/types';

import {
  AgendaViewModel,
  createAgendaViewModel,
} from './agendaViewModel/agendaViewModel';
import {
  createTitleViewModel,
  TitleViewModel,
} from './titleViewModel/titleViewModel';
import {
  createFiltersViewModel,
  FiltersViewModel,
} from './filtersViewModel/filtersViewModel';
import { DatePickerViewModel } from './datePickerViewModel/datePickerViewModel';
import { TimeZoneViewModel } from './timeZoneViewModel/timeZoneViewModel';

export type WidgetViewModel = {
  titleViewModel: TitleViewModel;
  datePickerViewModel: DatePickerViewModel;
  filtersViewModel?: FiltersViewModel;
  timeZoneViewModel?: TimeZoneViewModel;
  agendaViewModel?: AgendaViewModel;
};

export type WidgetViewModelFactory<ViewModel> = (
  args: ViewModelFactoryArgs,
) => ViewModel;

export const createDailyAgendaViewModel = (
  viewModelFactoryArgs: ViewModelFactoryArgs,
): WidgetViewModel => {
  return {
    filtersViewModel: createFiltersViewModel(viewModelFactoryArgs),
    titleViewModel: createTitleViewModel(viewModelFactoryArgs),
    agendaViewModel: createAgendaViewModel(viewModelFactoryArgs),
    datePickerViewModel: {
      availableDates: [],
    },
  };
};
