import { TimeSlot } from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import {
  convertRfcTimeToLocalDateTimeStartOfDay,
  getDateTimeFromLocalDateTime,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';

export const isAtLeastOneSlotIsWithOpenWaitingList = (
  slots: TimeSlot[],
): boolean => {
  return slots.some((slot: TimeSlot) => isSlotWithOpenWaitingList(slot));
};

export const isAllSlotsAreFull = (slots: TimeSlot[]): boolean => {
  return slots.every((slotAvailability: TimeSlot) =>
    isFullSlot(slotAvailability),
  );
};

export const isAllSlotsAreLocked = (slots: TimeSlot[]): boolean =>
  slots.length > 0 && slots.every(isSlotLocked);

export const isSlotLocked = (slot: TimeSlot): boolean =>
  !!slot.bookableCapacity && slot.bookableCapacity > 0;

export const isTooLateToBookAllSlots = (slots: TimeSlot[]): boolean => {
  return slots.every((slotAvailability: TimeSlot) =>
    isTooLateToBookSlot(slotAvailability),
  );
};

export const isTooEarlyToBookAllSlots = (slots: TimeSlot[]): boolean => {
  return slots.every((slotAvailability: TimeSlot) =>
    isTooEarlyToBookSlot(slotAvailability),
  );
};

export const isSlotWithOpenWaitingList = (slot: TimeSlot): boolean => {
  return (
    isFullSlot(slot) &&
    Number(slot.eventInfo?.waitingList?.remainingCapacity) > 0
  );
};

export const isFullSlot = (slot: TimeSlot): boolean => {
  return Number(slot.remainingCapacity) === 0;
};

export const isTooEarlyToBookSlot = (slot: TimeSlot): boolean => {
  return !!slot.bookingPolicyViolations?.tooEarlyToBook;
};

export const isTooLateToBookSlot = (slot: TimeSlot): boolean => {
  return !!slot.bookingPolicyViolations?.tooLateToBook;
};

export const isSlotWithBookingsPolicyViolation = (slot: TimeSlot): boolean => {
  return isTooEarlyToBookSlot(slot) || isTooLateToBookSlot(slot);
};

export const isSlotNoBookFlow = (slot: TimeSlot): boolean => {
  return !!slot.bookingPolicyViolations?.bookOnlineDisabled;
};

export const isSlotStartsOnDate = ({
  rfcStartTime,
  dateAsLocalDate,
}: {
  rfcStartTime: string;
  dateAsLocalDate: string;
}) => {
  const startOfDay = convertRfcTimeToLocalDateTimeStartOfDay(rfcStartTime);
  return (
    getDateTimeFromLocalDateTime(startOfDay).getTime() ===
    getDateTimeFromLocalDateTime(dateAsLocalDate).getTime()
  );
};
