export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  STRETCH = 'stretch',
}

export enum SlotsAvailability {
  ALL,
  ONLY_AVAILABLE,
}
export enum FilterType {
  SERVICE = 'SERVICE',
  LOCATION = 'LOCATION',
  STAFF_MEMBER = 'STAFF_MEMBER',
}

export type FilterOption = {
  id: string;
  value: string;
  selected: boolean;
};

export type Filter = {
  type: FilterType;
  label: string;
  options: FilterOption[];
};
